import { useEffect } from 'react';

const useKey = (keyCodeToCallback) => {
  let normalizedKeyCodesToCallback = keyCodeToCallback;
  if (!Array.isArray(keyCodeToCallback)) {
    normalizedKeyCodesToCallback = [keyCodeToCallback];
  }
  const handleKeyUp = (e) => {
    normalizedKeyCodesToCallback.forEach(({ keyCode, callback }) => {
      if (e.keyCode === keyCode) {
        callback();
      }
    });
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  });
};

export default useKey;