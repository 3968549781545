import React from 'react';
import instagram from '../../img/instagram.svg';
import spotify from '../../img/spotifySmall.svg';

const Social = () => (
  <nav className="socialMenu">
    <ul>
      {/*<li><a href="https://www.facebook.com/LGMXtechnofanfare/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>*/}
      <li><a href="https://www.instagram.com/jocusimano_and_thegatorclub?utm_source=qr&igshid=ZTM4ZDRiNzUwMw==" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a></li>
      {/*<li><a href="https://www.youtube.com/@lgmx?sub_confirmation=1" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" /></a></li>*/}
      {/*<li><a href="https://www.deezer.com/fr/artist/153180362" target="_blank" rel="noopener noreferrer"><img src={deezer} alt="deezer" /></a></li>*/}
      <li><a href="https://open.spotify.com/intl-fr/artist/5QorXU9T6lOK7Qvi0akFS3?si=pzEwENa_SpumRLk1BM-0Fw" target="_blank" rel="noopener noreferrer"><img src={spotify} alt="spotify" /></a></li>
    </ul>
  </nav>
);

export default Social;
