import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import nanoAudio from '../../audio/nano.mp3';
import thatsNewOrleansAudio from '../../audio/thatsNewOrleans.mp3';

const AudioPlayer = () => {
  const [src, setSrc] = useState(null);
  const player = useRef();

  useEffect(() => {
    if (player.current) {
      if (src) {
        player.current.play();
      } else {
        player.current.stop();
      }
    }
  }, src);

  const clickOnLine = (newSrc) => {
    if (newSrc === src) {
      setSrc(null);
    } else {
      setSrc(newSrc);
    }
  };

  return (
    <>
      <button type="button" onClick={() => clickOnLine(nanoAudio)} className={clsx(src === nanoAudio && 'isPlaying')}>
        <div>Jo Cusimano & the Gator Club - Nano</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(thatsNewOrleansAudio)} className={clsx(src === thatsNewOrleansAudio && 'isPlaying')}>
        <div>Jo Cusimano & the Gator Club - That's New Orleans</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>

      { src && (
        <audio id="audioContainer-player" ref={player}>
          <source src={src} type="audio/mpeg" />
        </audio>
      )}

    </>
  );
};

export default AudioPlayer;
