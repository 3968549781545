import React, {useLayoutEffect, useState} from 'react';
import { DateTime } from 'luxon';
import { clsx } from 'clsx';
import jose from '../../img//Presentation/jose.jpg';
import fred from '../../img//Presentation/fred.jpg';
import pl from '../../img//Presentation/pl.jpg';
import cedric from '../../img//Presentation/cedric.jpg';
import tommy from '../../img//Presentation/tommy.jpg';
import jeanphi from '../../img//Presentation/jeanphi.jpg';
import tristan from '../../img//Presentation/tristan.jpg';

const Presentation = () => {
  const arData = [
    {
      img: jose,
      titre: "Jo“Duke” Cusimano - Contrebasse/Chant",
      texte: "Avec ce panache rien qu’à lui, les gigs innombrables du “Duke” lui valent la reconnaissance de ses pairs autant que les faveurs d’un public conquis par le talent et la prestance du compositeur et frontman du Gator Club...",
    },
    {
      img: tristan,
      titre: "Tristan “El Shôgun” - SaXx",
      texte: "40 ans “d’airs-conditionnés” par son Alto inoxydable, le “ Shôgun” ne se résout pas à raccrocher « Pourvu que ça groove! » déclare-t-il simplement. Le feeling pour seule partition, on ne le verra jamais prendre un solo si ses pompes ne sont pas correctement cirées...",
    },
    {
      img: cedric,
      titre: "Cédric SanJuan “ L’élégant” - Batterie",
      texte: "Son drive solide met tout le monde d’accord. Tout sourire derrière ses tambours, il mène la danse et met la transe. Le dancefloor brûle? Il plaide coupable.",
    },
    {
      img: fred,
      titre: "Fred“Delta” Brousse - Guitare/Harmonica",
      texte: "Sa ES 345 ne sait même plus dire combien de bluesmen, de Chicago à Bâton Rouge, ont recruté “Delta Fred” comme side man de luxe. Il ballade sa classe tranquille en d’innombrables tournées, le boogie-blues toujours à portée de gâchette.",
    },
    {
      img: tommy,
      titre: "Tommy “Le Snake” Philys - Clarinette",
      texte: "Pour vous envoûter un boogie-woogie de traits de clarinette hypnotiques, sûr que le “Snake” n’a pas son pareil. Mais gare à la note piquée, fulgurante comme une de ses fameuses réparties et qui conclut souvent les débats. À surveiller...",
    },
    {
      img: pl,
      titre: "Paul-Louis “The Brain” Bertret - Trombone/Bugle/Trompette",
      texte: "Simple et sophistiqué, le “Pleuh” joue placé, il fait partie de ceux qui considèrent que le Jazz consiste à jouer n’importe quoi mais pas n’importe comment! Et pas avec n’importe qui, rajoute-t-il.. Bref un gars qui a de la bouteille!",
    },
    {
      img: jeanphi,
      titre: "Jean-Philippe“Yépalé” Coppens - Piano/Trombone",
      texte: "Bercé par l’esprit jazzy, il échoua de peu au concours Chopin de Varsovie et de rage, saute dans un jet pour la Nouvelle Orléans! On connait la suite, expulsé des USA après avoir soufflé dans un trombone, il trouve enfin refuge dans un style qui lui parle: le Gator Club.",
    },
  ];

  const [curIndex, setCurIndex] = useState(0);

  useLayoutEffect(() => {
    window.setInterval(() => {
      setCurIndex((curIndex + 1) % arData.length);
      if(curIndex === arData.length - 1) {
        setCurIndex(0);
      }
    }, 5000);
  }, [])


  return (
    <div className="stdPresentation">
      <div className="stdPresentation-top">
        {arData.map(({img, titre, texte}, index) => <img className={clsx(index === curIndex && 'act')} src={img} alt={titre} onMouseEnter={() => setCurIndex(index)}/>)}
      </div>

      <div className="stdPresentation-bottom">
        {arData.map(({img, titre, texte}, index) => <div className={clsx(index === curIndex && 'act')}>
          <div>{titre}</div>
          <div>{texte}</div>
        </div> )}
      </div>
    </div>
  );
};

export default Presentation;
