import React from 'react';
import AudioPlayer from './AudioPlayer';
import Gallery from './Gallery';
import logo from '../../img//logo.png';
import spotify from '../../img/spotify.svg';
import Social from './Social';
import Desc from "./Desc";
import Presentation from "./Presentation";

const Accueil = () => {

  const scrollTo = (id) => {
    const section = document.querySelector(id);
    section.scrollIntoView({behavior: 'smooth', block: 'start'});
  };


  return (
    <>

      <div id="page-accueil">

        <header>
          <nav>
            <ul>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part2')}>Accueil</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part3')}>Medias</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part4')}>Présentation</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part5')}>Contact</button>
              </li>
            </ul>
          </nav>

          <Social/>

        </header>

        <section id="page-accueil-part1">
          <img src={logo} id="page-accueil-part1-logo" alt="Logo Swing band danse Lyon"/>

        </section>

        <section id="page-accueil-part2">
          <Desc/>
        </section>

        <section id="page-accueil-part3">
          <div className="gradienTop"/>
          <div className="gradienBot"/>
          <div>
            <h2 className="stdTitle"><span>M</span>édias</h2>
            <br/>

            <Gallery/>

            <div className="audioContainer">
              <h2 className="stdTitle"><span>E</span>coutez</h2>
              <AudioPlayer/>

              <div className="audioContainer-liens">
                {/*<a href="https://www.youtube.com/@lgmx?sub_confirmation=1" target="_blank" rel="noopener noreferrer">*/}
                {/*  <img src={youtube} alt="Ecoutez sur youtube" />*/}
                {/*</a>*/}
                {/*<a href="https://www.deezer.com/fr/artist/153180362" target="_blank" rel="noopener"><img src={deezer} alt="Ecoutez sur deezer" /></a>*/}
                <a href="https://open.spotify.com/intl-fr/artist/5QorXU9T6lOK7Qvi0akFS3?si=pzEwENa_SpumRLk1BM-0Fw" target="_blank" rel="noopener noreferrer"><img src={spotify} alt="Ecoutez sur youtube"/></a>
                {/*<a href="https://lgmx.bandcamp.com/releases" target="_blank" rel="noopener noreferrer"><img src={bandcamp} alt="Ecoutez sur bandcamp" /></a>*/}
              </div>
            </div>
          </div>
        </section>
        <section id="page-accueil-part4">

          <h2 className="stdTitle">Le groupe</h2>
          {/*<Concerts />*/}
          <Presentation/>

        </section>
        <section id="page-accueil-part5">
          <div>
            <div>
              <h3>Contact / Booking <br/><small>josecusimano@gmail.com</small></h3>
            </div>
            <div>
              <h3>Social</h3>
              <Social/>

            </div>
          </div>
        </section>

        {/*<footer>*/}
        {/*  <nav>*/}
        {/*    <ul>*/}
        {/*      <li><a href="">copyright</a></li>*/}
        {/*      <li><a href="">mentions légales</a></li>*/}
        {/*    </ul>*/}
        {/*  </nav>*/}
        {/*</footer>*/}

      </div>
    </>
  );
};

export default Accueil;
