import React, { useState } from 'react';
import { clsx } from 'clsx';

const Desc = () => {
  const [lang, setLang] = useState('fr');
  return (
    <>
      { lang === 'fr' ? (
        <div className="page-accueil-part2-fr">
          <p>
            Voici en quelques mots, la philosophie du Gator Club: Il s’agit là d’une bande de voyous, mais ils jouent du swing et du Jazz New Orleans comme des gentlemen... Et surtout: ils jouent des compositions originales ! <br/><br/>
            <i>“Ce que nous jouons: c’est la vie !”</i><br/>
            Louis Armstrong
          </p>
          <p>
            Bienvenus dans l’univers du Gator Club, orienté en direction de la Louisiane, du Jazz New Orleans, du blues, du swing... Avec des musiciens chevronnés et passionnés, Jo Cusimano & the Gator Club transformeront votre évènement en fête swing des années 50' dans laquelle il est impossible de ne pas danser...
          </p>
          <p>
            Contactez nous pour vos festivals, fêtes de conscrits, programmation musicale, le groupe “Jo CUSIMANO & the Gator Club” vous propose une fête hors du commun, hors du temps, un concert live mais aussi une manière de voir les choses: la musique est notre voyage... Nous embarquons avec vous, vers un monde extraordinaire, un moment d’insouciance, bercés par des airs rythmés ou langoureux, simples, sophistiqués et pimentés à la fois, une parenthèse dans le temps: c’est ça le Gator  !
          </p>
        </div>
      ) : (
        <div className="page-accueil-part2-en">
           <p>
           In a nutshell, here's the Gator Club's philosophy: They're a bunch of thugs, but they play swing and New Orleans jazz like gentlemen... And best of all: they play original compositions! <br/><br/>
            <i>“What we play is life!”</i><br/>
            Louis Armstrong
          </p>
          <p>Jo CUSIMANO & the Gator Club" offers you an unusual, timeless celebration, a live concert but also a way of seeing things: music is our journey... We embark with you, into an extraordinary world, a carefree moment, lulled by rhythmic or languorous tunes, simple, sophisticated and spicy all at once, a parenthesis in time: that's the Gator Club!
          </p>
        </div>
      )}

      <button className={clsx(lang === 'en' && 'en')} onClick={() => setLang(lang === 'fr' ? 'en' : 'fr')}>&nbsp;</button>
    </>
  );
};

export default Desc;
